















































































































































































































































































































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import Method from '@/components/calculation/method/index.vue';
import { cleanSource, getIconByProfessionCode } from '@/helpers/common';
import EditItem from '@/components/calculation/new-building-item/edit-item/index.vue';
import FromDefault from '@/components/calculation/new-building-item/from-default/index.vue';
import FromCalculations from '@/components/calculation/from-calculations/index.vue';
import FromLibrary from '@/components/calculation/from-library/index.vue';
import DialogTitle from '@/components/common/dialog-title/index.vue';
import Profession from '@/components/profession/index.vue';
import { TimeEstimate } from '@/models/time-estimate/interface';
import { AddItemMethod } from '@/constants/enum';
import {
  BuildingElementCalculation,
  BuildingItem,
} from '@/models/building-element/interfaces';
import { BUILDING_ITEM_HEADERS_SIMPLE } from '@/constants/table-headers';
import { useBatch } from '@/modules/batch';
import { useCalculation } from '@/modules/calculation';
import { BuildingItemTemplate } from '@/models/library/interfaces';
import { useBuildingItem } from '@/modules/building-item';
import { buildItemCalculations } from '@/helpers/building-item';
import { v4 as uuidv4 } from 'uuid';
import DataTableRowHandler from '@/components/common/table-row-handler/index.vue';
import draggable from 'vuedraggable';
import KalkHelper from '@/components/common/kalk-helper/index.vue';

export default defineComponent({
  props: {
    isMenuitem: {
      type: Boolean,
    },
    element: {
      type: Object as PropType<BuildingElementCalculation>,
      required: true,
    },
    primary: {
      type: Boolean,
    },
  },
  components: {
    FromDefault,
    FromLibrary,
    FromCalculations,
    Method,
    DialogTitle,
    EditItem,
    Profession,
    DataTableRowHandler,
    draggable,
    KalkHelper,
  },
  setup(props) {
    const { projectCalculation } = useCalculation();

    const loading = ref<boolean>(false);
    const dialog = ref<boolean>(false);
    const search = ref<string>('');
    const method = ref<AddItemMethod | undefined>(AddItemMethod.CreateNew);
    const estimate = ref<TimeEstimate>();

    const buildingItems = ref<Array<BuildingItem>>([]);

    const close = () => {
      estimate.value = undefined;
      search.value = '';
      method.value = AddItemMethod.CreateNew;
      buildingItems.value = [];
      dialog.value = false;
    };

    const selectMethod = (sel: AddItemMethod) => {
      method.value = sel;
    };

    const addFromDefault = (bic: BuildingItem) => {
      buildingItems.value.push(bic);
    };

    const addFromCalculation = (bic: BuildingItem) => {
      const newItem: BuildingItem = cleanSource(bic);
      newItem.Id = uuidv4();
      newItem.BuildingElementId = props.element.Id;
      buildingItems.value.push(newItem);
    };

    const addFromLibrary = (template: BuildingItemTemplate) => {
      const { createFromBuildingItemTemplate } = useBuildingItem();

      if (props.element.Id) {
        const newItem = createFromBuildingItemTemplate(
          template,
          props.element.Id
        );
        buildingItems.value.push(newItem);
      }
    };

    const removeItem = (item: BuildingItem) => {
      const index = buildingItems.value.findIndex(e => e.Id === item.Id);
      if (index >= 0) {
        buildingItems.value.splice(index, 1);
      }
    };

    const submit = () => {
      const { registerNewBuildingItems } = useBatch();
      loading.value = true;
      let index = 1;
      for (const item of buildingItems.value) {
        if (props.element && props.element.BuildingItems.length >= 0) {
          item.SortOrder = props.element.BuildingItems.length + index;
        }
        index++;
      }
      registerNewBuildingItems(buildingItems.value);
      const becs = buildItemCalculations(buildingItems.value, props.element);
      becs.forEach(bec => {
        bec.IsRecentlyAdded = true;
      });
      props.element.BuildingItems = props.element.BuildingItems.concat(becs);
      loading.value = false;
      close();
    };

    const onMoveCallback = (evt: {
      draggedContext: { element: BuildingItem; futureIndex: number };
    }) => {
      const item = evt.draggedContext.element;
      const itemIdx = evt.draggedContext.futureIndex;

      item.SortOrder = itemIdx;

      return true;
    };

    return {
      addFromCalculation,
      addFromLibrary,
      close,
      getIconByProfessionCode,
      selectMethod,
      submit,
      removeItem,
      addFromDefault,
      onMoveCallback,
      dialog,
      loading,
      method,
      search,
      AddItemMethod,
      buildingItems,
      BUILDING_ITEM_HEADERS_SIMPLE,
      projectCalculation,
      allowDrag: true,
    };
  },
});
